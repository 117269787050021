.WelcomeTitle {
  font-size: 65px;
  font-weight: 100;
  letter-spacing: 3px;
  margin-top: 70px;
  text-align: center;
  color: white;
}

.WelcomeDescription {
  font-size: 16px;
  color: #c3c5cc !important;
  font-weight: 200;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.StartBuildingButton {
  width: 300px;
  max-width: 100%;
  height: 50px;
  margin-top: 50px;
}

.DocumentLink {
  margin-top: 2%;
  font-size: 16px;
  color: white;
  font-weight: 200;
}

.DocumentLink a {
  color: white;
  font-weight: 400;
}
