.RequestAccessCard {
  border-radius: 6px;
  text-align: left;
  margin-top: 80px;
  margin-bottom: 30px;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px !important;
  font-weight: 200;
  box-shadow: 0 8px 20px 0 rgba(172, 180, 187, 0.21);
  background: white;
  color: #3d3d3d;
  padding: 20px;
  display: inline-block !important;
  width: 100%;
  max-width: 500px;
}
@media (max-width: 768px) {
  .RequestAccessCard {
    margin-top: 40px;
  }
}

.RequestAccessButton {
  width: 100%;
}

.RequestCompleteImgContainer {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
