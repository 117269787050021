.App {
  text-align: center;
  height: 100vh;
}

.App-header {
  background-color: #343a3e !important;
  height: 54px !important;
  line-height: 54px !important;
  padding: 0 !important;
  color: white;
  text-align: left;
  vertical-align: top;
}
.App-list {
  width: 40px;
  float: left;
  height: 100%;
  background-color: transparent;
  padding-left: 20px;
}
.Logo {
  position: relative;
  left: 25px;
}
.Logo-title {
  position: relative;
  left: 10px;
}

.Items {
  height: 100%;
  margin-right: 25px;
  float: right;
}

.Items a {
  position: relative;
  display: inline-block;
  margin-left: 25px;
  color: white;
  font-size: 14px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.WholePane {
  min-height: 100% !important;
}

.MainLayout {
  display: flex !important;
  width: 100% !important;
  height: calc(100vh - 54px);
}

.MainContent {
  display: flex !important;
  width: 100% !important;
  background: white;
}

.MainSider {
  background-color: white !important;
  border-right: 1px solid #e8e8e8 !important;
  text-align: left !important;
}

.MainSider ul {
  border-right: 0;
}

.MainSider li {
  height: 50px !important;
  margin: 0 0 !important;
}

.MainSider li a {
  height: 100%;
  width: 100%;
  line-height: 50px;
}

.LogoutButton {
  margin-top: 20px;
}

.AppCredentialField ::-webkit-scrollbar-track {
  width: 10px;
  background-color: #f8f9fb;
}

.AppCredentialField ::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.AppCredentialField ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(214, 214, 214);
}

.sharpen-img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}
