.OnboardMainPane {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  background-image: url('https://s3-us-west-1.amazonaws.com/bitfinder/img/dev_console_bg.jpg');
  padding: 20px;
  /* padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px; */
  width: 100% !important;
  text-align: center !important;
  overflow-y: overlay;
}

.Layout {
  height: 100vh;
}
