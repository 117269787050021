.TiersPane {
  text-align: center !important;
  margin-top: 30px;
  margin-bottom: 0px;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px !important;
  font-weight: 10;
  color: #3d3d3d;
  width: 1200px !important;
  display: inline-block !important;
}

.TiersRow {
  width: 100%;
  display: inline-block !important;
}

.gutter-box {
  box-shadow: 0 8px 20px 0 rgba(172,180,187,0.21);
  background: white !important;
  border-radius: 6px;
}

.gutter-box-selected {
  box-shadow: 0 8px 20px 0 rgba(172,180,187,0.21);
  background: white !important;
  border-radius: 6px;
  border-width: 7px;
  border-style: solid;
  border-color: #4EAF53 !important;
}

.TierCard {
  background: transparent !important;
  border-radius: 6px !important;
}

.TiersTitle {
  font-size: 50px;
  font-weight: 100;
  letter-spacing: 3px;
  text-align: center;
  color: white;
}

.TiersDescription {
  font-size: 16px;
  color: #c3c5cc !important;
  font-weight: 200;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  margin-bottom: 50px;
}

.ant-card-head {
  background: transparent !important;
  /* padding: 10px; */
}

.CheckMark {
  font-weight: 600;
  color: #4EAF53;
}

.XMark {
  font-weight: 600;
  color: #F34459;
}

.FeatureRow {
  margin-bottom: 10px;
}

.PricingValue {
  font-weight: 300;
  font-size: 22px;
}

.Unimplemented {
  color: rgba(136, 136, 136, 0.658)
}
