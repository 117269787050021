.DataDocPane {
  background-color: white !important;
  padding: 0;
  margin: 0;
  width: 100%;
}

.DocPane {
  width: 100%;
  padding-top: 40px !important;
  padding-bottom: 70px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-right: 1px solid #e8e8e8;
  text-align: left !important;
}
@media (max-width: 768px) {
  .DocPane {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.DataDocsIFrameDiv {
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: center;
}
