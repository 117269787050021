.LoginCard {
  border-radius: 6px;
  text-align: left;
  margin-top: 80px;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px !important;
  font-weight: 200;
  box-shadow: 0 8px 20px 0 rgba(172, 180, 187, 0.21);
  background: white;
  color: #3d3d3d;
  display: inline-block !important;
  width: 500px;
  max-width: 100%;
}
@media (max-width: 768px) {
  .LoginCard {
    margin-top: 40px;
  }
}

.LoginButton {
  width: 100% !important;
}

.LoginForm {
  margin-top: 30px !important;
}
.LoginFormLastItem {
  margin-bottom: 0;
}
