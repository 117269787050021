.MainPane {
  background-color: white !important;
  padding: 20px;
  width: 100%;
}

.MainCard {
  border-radius: 6px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px !important;
  font-weight: 200;
  box-shadow: 0 8px 20px 0 rgba(172,180,187,0.21);
  background: white;
  color: #3d3d3d;
  padding-bottom: 10px;
}

.Credentials {
  padding-top: 2px;
}

.CredentialField {
  border-radius: 3px;
  background-color: #F8F9FB;
  font-size: 13px;
  font-weight: 200;
  padding: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #DEE2E5;
  word-wrap: break-word;
}

.MainCard .ant-btn {
  margin: 10px 10px 0 0;
  display: inline-block;
  text-align: center;
  line-height: 30px;
}
