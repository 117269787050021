.WhatsNewPane {
  background-color: white !important;
  padding: 40px 60px 60px 60px;
  width: 1000px;
  text-align: left !important;
}

@media (max-width: 768px) {
  .WhatsNewPane {
    padding: 40px 20px 60px 20px;
  }
}

.WhatsNewHeader {
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

.WhatsNewHeader p {
  margin-bottom: 30px;
}
