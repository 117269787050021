.MainPane {
  background-color: white !important;
  padding: 40px 60px;
}
@media (max-width: 768px) {
  .MainPane {
    background-color: white !important;
    padding: 40px 20px;
  }
}

.Header {
  text-align: left;
}

.AppList {
  padding-top: 30px;
  padding-bottom: 30px;
}

.AppCard {
  border-radius: 6px;
  text-align: left;
  margin-bottom: 30px;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 17px;
  box-shadow: 0 8px 20px 0 rgba(172, 180, 187, 0.21);
  background: white;
  color: #3d3d3d;
}

.AppDescription {
  font-size: 14px;
  font-weight: 200;
}

.AppCredentials {
  padding-top: 2px;
}

.AppCredentialField {
  border-radius: 3px;
  background-color: #f8f9fb;
  font-size: 13px;
  font-weight: 200;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #dee2e5;
  height: 100%;
}

.AppNameInput {
  margin-bottom: 15px;
}

.AppButtonsRow {
  margin-top: 20px;
}

.DeleteAppButton {
  margin-left: 10px;
}

.EmptyList {
  text-align: left;
}

.UnauthorizedExplanation {
  text-align: left !important;
}

.ClientConfig {
  margin-bottom: 15px;
}

.ClientConfig table {
  overflow-x: hidden;
  border-collapse: collapse;
  width: 100%;
}

.TableHeader {
  border-bottom: 1px solid rgb(232, 232, 232);
}

.TableHeader th {
  font-weight: 500;
  padding: 12px 8px;
}

.Fields td {
  width: 33%;
  padding: 12px 8px;
}

.ClientPermissions {
  margin-bottom: 15px;
}

.ClientPermissions td {
  width: 33%;
  border-collapse: collapse;
  border-spacing: 0px;
}

.Spin {
  margin-top: 150px;
  margin-left: 200px;
}

.NoOAuthPermissionDiv {
  margin-top: 13%;
  margin-left: 25%;
}
.ClientPermissionsTable {
  height: 260px;
  overflow-y: scroll;
}
