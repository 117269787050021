.MiniTitle {
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 400 !important;
}

.Layout {
  height: 100vh;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}
